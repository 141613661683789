var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            attrs: { size: "medium" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content bgFFF" },
      [
        _c("TitleModule", { attrs: { title: "商家管理员详情" } }),
        _c(
          "el-form",
          {
            staticStyle: { width: "400px", float: "left", "margin-left": "0" },
            attrs: {
              "label-position": "left",
              "label-width": "100px",
              model: _vm.formInline,
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: "商家姓名:", prop: "storeNames" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.formInline.storeNames))])]
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: "手机号:", prop: "mobile" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.formInline.mobile))])]
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: "登录名:", prop: "account" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.formInline.account))])]
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: "邮箱:", prop: "" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.formInline.email))])]
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: "性别:", prop: "sex" },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.formInline.sex == 1 ? "男" : "女")),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }